import React, { useEffect } from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import { enhanceText } from './FunctionEnhanceText';


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
Jim@GenerativeNFTs.io   •   (503) 891-7153
https://jpd3.com   •   https://linkedin.com/in/jimdee   •   https://github.com/jpdnft

____________________________________________________________________

PROFESSIONAL SUMMARY

Web3 developer and blockchain strategist with a proven track record of taking NFT and blockchain projects from 0 to 1, leading generative NFT drops, and developing large-scale decentralized applications (dApps). Over $40M+ in NFT sales through smart contract development, art pipeline automation, and project leadership. Skilled in collaborating with international teams and driving full-stack Ethereum development. Recognized thought leader in the NFT and Web3 space through technical writing, public speaking, and advisory roles. Open to leadership, devrel, tech documentation, and content creation opportunities in the Web3 ecosystem.

____________________________________________________________________

TECHNICAL SKILLS

• Blockchain & Web3: 
  Smart Contracts, ERC721/721A, Ethereum, Arweave, IPFS, Theta, OpenSea, MetaMask, Magic Eden, Ethers.js, Coinbase SDK

• Programming & Development: 
  Solidity, React, JSX, JavaScript, TypeScript, PHP, MySQL, HTML, CSS, Hardhat, Node.js, Firebase, GitHub

• SEO & Digital Marketing: 
  Search Engine Optimization, Content Strategy, Social Media Marketing, Google Analytics, Facebook Ads

• Project Management: 
  Agile Methodologies, Team Leadership, Strategic Planning, Art Pipeline Management, International Team Coordination

____________________________________________________________________

PROFESSIONAL EXPERIENCE

★ GenerativeNFTs.io ⟶ Co-Founder / Chief Generative NFT Coder / Project Lead
Location: Remote, Duration: Ongoing

• Co-founded GenerativeNFTs.io in early 2021 after going viral for my generative art coding writings
• Considered perhaps the world's leading authority on rarity tables and other aspects of generative NFT coding.
• Met with many hundreds of NFT team founders since 2021
• Served on 30+ worldwide generative NFT drop teams since early 2021
• NFTs originating from my personal machine have generated $40+ million USD in total revenues.
• Experienced in complex, international, large-scale web3 project management, art pipeline management, art/metadata coding, NFT team strategy, web3 advisory services, and web3 dev / coding.
• Creator / writer of a popular web3/NFT blog.
• Organized archive of ~200 original NFT articles since early 2021 — see https://generativenfts.io/blog — covers generative art, NFT team building, NFT team management, NFT strategy, branding, and much more in the web3 space.
• Especially sought out for generative NFT coding, for which I've produced around 300,000 generative NFTs to date.
• Spearheaded many sellout sets (all on the ETH chain except for one on the Theta chain — the “Napoleon Dynamite” drop by actor Jon Heder).
• Personally deployed 30+ smart contracts onto the Ethereum mainnet.
• Comfortable working with executives and team-leads worldwide.
• Excellent contacts worldwide in the NFT / crypto space, as well as metaverse builders and entrepreneurs.
• Tech stack: Solidity / Hardhat, React/JSX, JavaScript, PHP, MySQL, HTML/CSS, IPFS / Arweave

____________________________________________________________________

SELECTED WEB3 PROJECTS

• SuperFuzz Bad Guys (sold out — generative art coding / team strategy)
• PPPandas (sold out — generative art coding)
• SuperFuzz Good Guys (sold out — generative art coding / team participation)
• Chef Dogs Gen1 (minting — gen art, smart contract, minting dApp)
• Sandhelm (minting — gen art, smart contract, minting dApp, team participation)
• Praying Hands Club (sold out — gen art, smart contract, minting dApp, marketing/strategy team members)
• The Crypto Mos (gen art, smart contract, minting dApp, team participation)
• I'm a Kitty Cat (minting — gen art, smart contract, minting dApp, marketing/strategy team members)
• Order of the Tigons (sold out — the "Napoleon Dynamite" drop on Theta chain — art coding and team participation)
• Year of the Woman (sold out — gen art, smart contract, minting dApp, marketing/strategy team members)
• Loopies (minted out — my own free mint project — did everything)
• Astral Pioneers (minting — gen art, smart contract, minting dApp)
• Chef Dogs Gen2 (minting — gen art, smart contract, minting dApp)
• NFTGreetings.xyz (minting — personal project with 11 mainnet contracts/minting dApps launched)
• FrownyTown (gen art, smart contract, minting dApp, advisory services)
• LAPiNC (gen art, smart contract, minting dApp, advisory services)
• Spiritual Beings: (gen art, smart contract, minting dApp, team participation. Led by former chief of staff of Boss Beauties)
• Mighty Metas (gen art, smart contract, minting dApp, advisory services)
• Inner Kiddos Club (minting — smart contract & minting dApp)
• First Ape Wives Club (minting — gen art, smart contract, minting dApp, team members)
• GlitchDeck (minting — personal project consisting of 8,100 unique, generated, animated playing cards)
• Divas by BLAQ (minting — gen art, smart contract, minting dApp)

____________________________________________________________________

ADDITIONAL TECH SKILLS

AI, APIs, analytics, arweave, bitcoin, blockchain, blur, cms, chatGPT, coinbase, css, crypto, cryptocurrency, dApps, database application, databases, dex tools, doge, ens, erc721, erc721a, eth, ethereum, ethers.js, ethscriptions, facet nfts, firebase, generative art coding, github, google analytics, hardhat, html, inscriptions, intranets, ipfs, javascript, joomla, jsx, lamp, magic eden, meme coins, metadata, metaverse, mysql, netlify, node.js, non-fungible tokens, opensea, ordex, php, polygon, problem solving, programming, react, solidity, uniswap, web content, web design, web developer, web development, web designer, web publishing, web3, web3 tech, web3 trends, wordpress, zod

____________________________________________________________________

REPRESENTATIVE WEB3 ARTICLES

Explore a large, organized archive of my NFT, blockchain, and web3-related articles at https://generativenfts.io/blog

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/


`;


const ResumeWeb3 = () => {

    useEffect(() => {
      enhanceText();
    }, []);
  
  return (
    <div>
         
         <MainHeader />

         <style>{`
          @media print {
            div.mainpageHeader, p.breadcrumbs, h1, div.mainpageFooter, p.xprint, textarea { display: none; }
            div.mainpage { border:initial; }
          }
        `}</style>

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>WEB3 / BLOCKCHAIN RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p class="xprint"><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p class="xprint" className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeWeb3;
