import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Other = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>OTHER INTERESTS</h1>

            <img src="/slater.jpg" className="imgRight1 shadow-soft"/>

            <ul>
              <li><strong>Photography:</strong></li>
              <ul>
              
                <li><strong>Rock &amp; roll:</strong> <a href="https://www.flickr.com/photos/rock-photography/" target="_blank">Here are some pics</a> from Sasquatch Music Festival and Pickathon (both of which I attended as press/media).</li>
                
                <li><strong>Travel:</strong> My wife and I both love to go on photo expeditions while abroad. In recent years, we've lived in Thailand, India, Mexico, and other countries. The volume of photos is, frankly, way too overwhelming for me to organize. Perhaps in the future we'll post some sets from our times in these countries.</li>
                
                <li><strong>Cats</strong>:  Somehow, I took to photographing cats in my neighborhood (SE PDX). If you like cats, and want to see more than 100 different ones that live within about 10 blocks of my home, well, <a href="https://www.facebook.com/media/set/?set=a.10150190529658567.324627.680373566&amp;type=1&amp;l=4ef7bb9e08" target="_blank">here you go</a>.</li>

                <li><strong>Juggling</strong>: I've gotten away from it over the years, but I really do love juggling, and plan to take it up again when I get time (someday!).</li>
              
              </ul>
                                          
              <li><strong>Raw and living foods,</strong> general nutrition. Have written and published hundreds of thousands of words worth of health and nutrition articles on the <a href="https://purejeevan.com">Pure Jeevan blog</a>, and also produced and edited many <a href="https://www.youtube.com/watch?v=Rbaqb2WLpbk" target="_blank">entertaining instructional videos</a> (such as that quirky sample).  </li>
                            
              <li><strong>eBay</strong>. Formerly a PowerSeller, circa 2005. Trade under the names “<a href="http://myworld.ebay.com/jimdee" target="_blank">jimdee</a>“ and  “<a href="http://myworld.ebay.com/webauctionstore/" target="_blank">WebAuctionStore</a>.” Proudly maintain a 100% positive feedback rating, with 650+ total transactions with feedback since 1998.</li>
              
            </ul>

            <h2>MISC OTHER STUFF</h2>
            <ul>
              <li><a href="misc/text-helper.html">Text Utility</a> (Just something I use from time to time for building Tweets or other character-limited content.)</li>
              <li>For some reason, I made <a href="https://www.youtube.com/@GetDownAndDoIt/videos" target="_blank">about 150 videos about doing 100 push-ups per day</a>. No idea why, although it'd be cool if I'd kept up with that.</li>
            </ul>

            <div className="clear"></div>
            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Other;
