import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import ThreeJSCanvas from './ThreeJSCanvas'; 

const Contact = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>CONTACT JIM</h1>            

            <div className="MML shadow-soft">
              <ThreeJSCanvas /> 
              <hr style={{marginTop:30}}></hr>
              <p style={{fontSize:13}}>The cat here is just a fun little AI-generated 3D model that you can interact with using the mouse, the up/down scroll, and/or the space bar. (<a href="https://medium.com/web-design-web-developer-magazine/adding-an-ai-generated-3d-spinning-cat-to-a-react-app-using-meshy-ai-4de44aa5d008?sk=ffabee08b3c3b137f97a567514248fe0" target="_blank">How I did this</a>.)</p>
            </div>

            <img className="aiprofilepic shadow-soft" src="aiprofilepic.jpg" />

            <p>➡️ <b>Drop me a line:<br></br> Jim [at] GenerativeNFTs.io</b> ⬅️</p>
                    
                    <p>or...</p>

            <p>➡️ <b>Drop me a line:<br></br> Jim [at] ArrayWebDevelopment.com</b> ⬅️</p>


            <div className="clear"></div>

        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default Contact;
