import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import { FaAmazon, FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaMedium, FaTwitter } from 'react-icons/fa';

const Socials = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>SOCIALS</h1>
            <p className="restitle">See LinkedIn for references and testimonials given and received:</p>

            <div className="lrbox">

              <ul className="fa-ul no-bullets alternate socialList">

                <li> <a href="https://amazon.com/author/jimdee" target="_blank"><FaAmazon /> Amazon
                </a></li>

                <li><a href="https://www.facebook.com/rawfood/" target="_blank">
                <FaFacebook /> Facebook
                </a></li>

                <li><a href="https://github.com/jpdnft" target="_blank">
                <FaGithub /> Github
                </a></li>

                <li><a href="https://www.instagram.com/SwiggaJuice/" target="_blank">
                <FaInstagram /> Instagram 
                </a></li>

                <li><a href="https://www.linkedin.com/in/jimdee/" target="_blank">
                <FaLinkedin /> LinkedIn
                </a></li>

                <li><a href="https://medium.com/@jimdee" target="_blank">
                <FaMedium /> Medium
                </a></li>

                <li><a href="https://www.twitter.com/SwiggaJuice/" target="_blank">
                <FaTwitter /> Twitter
                </a></li>	
              </ul>

            </div>

            <div className="lrbox">

              <div className="video-wrapper">
                <figure>
                  <video width="400" height="400" autoPlay loop muted  className="shadow-soft">
                    <source src="/socialcat.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </figure>
              </div>

            </div>




              <div className="clear"></div>

              <p className="topButton"><a href="#top">/top</a></p>
              <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Socials;
