import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';

const BookPirates = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <p className="breadcrumbs"><a href="/books">Back to /books</a></p>

            <h1>THE PIRATES OF PAMLICO SOUND</h1>

            <div className="rightPromoBox">
                <a href="https://www.amazon.com/dp/1702594769" target="_blank"><img src="../pirates-paperback-cover.jpg" /></a><br></br>
                <Button href="https://www.amazon.com/dp/1702594769">Go to Amazon</Button> 
            </div>

            <img className="bookbanners" src='../article-pirates.jpg' />

            <p><em>"The magic has slipped away,</em> Bernice Sarris thought to herself over a Starbucks grande cappuccino." She'd been thinking of her love life, how she and her husband had let years career-building and day-to-day life come between them, as it does for so many others.</p>

            <p>Her two best friends, Nicki and Jayne, are in the same boat -- all of them successful professionals, yet the romance has faded with their husbands, as well.</p>

            <div className="video-wrapper">
              <figure>
                <video width="400" height="400" autoPlay loop muted  className="shadow-soft">
                  <source src="/piratespromo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <figcaption>A glimpse into the tale (AI-generated video I made using Sora!).</figcaption>
              </figure>
            </div>

            <p>Things change, however, when Bernice acquires an authentic pirate jacket from the 1700s. As each of the women convince their husbands to don the jacket, each couple experiences a romantic renewal. But, more importantly, Bernice's discovery may well right some terrible wrongs from long, long ago.</p>

            <p><em>The Pirates of Pamlico Sound</em> alternates between the past (starting out in 1692 in Salem, Massachusetts) and the present (Outer Banks, North Carolina), tracking the history of this potentially magical pirate jacket and other important artifacts from the early 1700s.</p>

            <p>Readers who enjoy supernatural historical romance -- with themes of witchcraft and pirates / piracy -- will encounter characters like Aithne Reade (who escapes the Salem witch trials), her daughter Brenna Reade, Chogan (the Wokokon medicine man who mentors Brenna), Hervé Grellier (a meddling pastor and painter), and of course Edward Teach (better known as Blackbeard).</p>

            <Button href="https://www.amazon.com/dp/1702594769">Grab PIRATES from Amazon!</Button>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default BookPirates;
