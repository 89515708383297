import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';

const BookFrazyCreek = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <p className="breadcrumbs"><a href="/books">Back to /books</a></p>

            <h1>FRAZY CREEK</h1>

            <div className="rightPromoBox">
                <a href="https://www.amazon.com/dp/B0CKY4ZXV8" target="_blank"><img src="../frazycreek-paperback-cover.jpg" /></a><br></br>
                <Button href="https://www.amazon.com/dp/B0CKY4ZXV8">Go to Amazon</Button> 
            </div>

            <img className="bookbanners" src='../book-head-frazy.jpg' />

            <p>Drawing parallels to renowned franchises like <em>Straight Outta Compton, Hamilton,</em> and <em>Breaking Bad,</em> <b>FRAZY CREEK</b> invites readers to witness the intense origin stories of two cats caught up in the gritty urban gangland of Long Beach, California (aka 'the LBC'). Told through a richly woven tapestry of dark humor and well-structured feline complexity, readers watch two rival cat gangs vie for dominance -- the Frazy gang and the Tail Collectaz. On the Frazy side, our anti-hero 'Trill' reluctantly rises to power after a brutal attack. On the Tail Collecta side, antagonist 'DragonDrop' climbs the ranks fueled by a new wonder-drug called 10-L. As conflicts escalate, the novel delves into the complexities of duty, loyalty, ambition, fate, and the darkness that power can unleash.</p>

            <div className="video-wrapper">
              <figure>
                <video width="500" height="500" autoPlay loop muted  className="shadow-soft">
                  <source src="/frazypromo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <figcaption>A glimpse into the territory (AI-generated video I made using Sora!).</figcaption>
              </figure>
            </div>

            <p>Built atop the city's deep hip-hop roots and interwoven with original cat gangster rap verse, <b>FRAZY CREEK</b> paints a vivid picture of a lurid and often brutal feline underworld. The tale captures the essence of cat gang life, crafting a unique and darkly humorous literary experience rife with symbolism, thematic depth, satire, and cultural insights. With a present tense style that quickly immerses readers into its world via a sprawling cast of characters and situations, <b>FRAZY CREEK</b> offers readers a uniquely engaging and surprisingly literary story. The book strives to capture the intensity often portrayed in gangster rap, but using cats as characters, which confers a humorous effect.</p>

            <p>If you know Snoop Dogg's <em>Doggystyle</em> CD (1993), or if you've caught some old-school, cult, gang-type films like <em>The Warriors</em> (1979), some of the conventions used in the book would be familiar (<em>e.g.,</em> the inclusion of a recurring DJ character who chimes in between chapters to provide commentary and spin the latest cat hip-hop chartbusters).</p>

            <p>While <b>FRAZY CREEK</b> may appeal to some young adults, it's imporrtant to note that the book contains mature themes. <b>FRAZY CREEK</b> is warring cat gangs, dirty cat cops, cat prostitutes, cat rappers, rival cat record labels, and cat gang bangers. The book contains nearly 50 original cat hip-hop tunes by the world's most renowned cat rappers. Much like their human counterparts, cat gangster rap often contains adult language and/or themes that some may find offensive.</p>

            <Button href="https://www.amazon.com/dp/B0CKY4ZXV8">Grab Frazy Creek from Amazon!</Button>

            <p>&nbsp;</p>

            <p><b>CATGANGS.com</b>!  I also purchased the domain Catangs.com for this book. Right now, it's just a placeholder with a link to Amazon, but I'm happy to own that domain for future development.</p>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default BookFrazyCreek;
