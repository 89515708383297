import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Education = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">

            <h1>EDUCATION</h1>

            <div className="video-wrapper">
                <figure>
                  <video width="400" height="400" autoPlay loop muted className="shadow-soft">
                    <source src="/studycat.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </figure>
              </div>

            <ul>
              <li><strong>B.A. in English</strong> (concentration in writing) -– <a href="http://www.wilkes.edu/" target="_blank">Wilkes University</a>, Wilkes-Barre, PA (1991). Naparsteck scholarship for writing, co-editor of the university's literary magazine. </li>
              
              <li>I began an MFA program at George Mason University after college, but <a href="https://writingcooperative.com/the-disastrous-tale-of-a-creative-writing-mfa-dropout-d75a0145e3b8">it was a disaster</a>. I left the program to pursue a corporate position.</li>

              <li><b>Board of Directors:</b></li>
              <ul>

                <li><a href="http://www.thinkhawthorne.com/" target="_blank">Hawthorne Boulevard Business Association</a>. (2011-2013).</li>
                <li><a href="http://www.pittsburghbotanicgarden.org/" target="_blank">Pittsburgh Botanic Garden</a>. (2006-2010). Served as Finance Committee chairman during the kickoff of a $30 million capital campaign and nationally important acid mine drainage reclamation project involving coal mines on public property. Wrote the organization's business plan in 2006, which has helped secure various grants. Also lead a small membership committee and participated in marketing.</li>
                <li>See also:  "<a href="/documents/ss_horti.pdf">Boards-by-Design Match: Jim Dee and The Horticultural Society of Western Pennsylvania</a>.” Featured article on Duquesne University's <em>Nonprofit Leadership Institute</em> web site, 2006.</li>

              </ul>

            </ul>
            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Education;
