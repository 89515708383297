import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';

const BookChroo = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
           <p className="breadcrumbs"><a href="/books">Back to /books</a></p>
            
            <h1>CHROO</h1>

            <div className="rightPromoBox">
                <a href="https://www.amazon.com/gp/product/1705385133" target="_blank"><img src="../chroo-paperback-cover.jpg" /></a><br></br>
                <Button href="https://www.amazon.com/gp/product/1705385133">Go to Amazon</Button> 
            </div>

            <img className="bookbanners" src='../article-chroo.jpg' />

            <p><strong>Who is CHROO?</strong> ... He's quite possibly the richest dog in the world, the ever-present Corona-swilling BFF to celebrity heiress Upferra Quiquis (pronounced “quickie”). Chroo's been everywhere, knows everyone, and (unusual for a Chihuahua) boasts an impressive track record with large-breed females. In this screwball literary fantasy, the fun starts when Upferra (who isn't the dumb blonde everyone thinks) finds out she's inheriting vastly more than the billions expected. It turns out her father was one of eight trillionaires controlling the world's economy by playing a strange, secretive Game. She's inherited his seat in this Game and it's now literally her move. The problem is, she doesn't care to play. So, she, Chroo, and an ensemble cast of humans and animals work to take down the Game, thus restoring freedom to the entire world!</p>

            <p>CHROO brings readers along for a whirlwind global adventure. Those who like quirky tales will love the endless pop-culture and literary references, animal philosophy, and situational hijinks — from the book's opening through its climax featuring an invasion of cross-dressing macaques (as told by a thespian cat in four pages of bona fide dactylic hexameter). #weird #funny #chroo</p>

            <hr></hr>

            <h3>Who Would Enjoy Reading CHROO?</h3>

            <div className="video-wrapper">
              <figure>
                <video width="500" height="500" autoPlay loop muted  className="shadow-soft">
                  <source src="/chroopromo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <figcaption>A glimpse into the adventure (AI-generated video I made using Sora!).</figcaption>
              </figure>
            </div>


            <ul>
              <li>Readers who enjoy well-crafted, literary prose;</li>
              <li>Readers who love plot-driven novels;</li>
              <li>Animal lovers — especially dogs and cats (and perhaps most notably Chihuahua lovers);</li>
              <li>Those who appreciate humor, word-play, pop-culture and literary references, and a flirtation with meta-fiction at times;</li>
              <li>Readers who would appreciate a smart, complex female protagonist;</li>
              <li>Readers who might enjoy an ensemble-cast;</li>
              <li>Those who like quirky, mysterious, and adventurous tales with diverse international locations;</li>
              <li>Those who appreciate speculative, upmarket adult fantasy fiction (i.e., a world where a few select animals can talk to a few select humans, where the world run, in part, as a literal game, etc.).</li>
            </ul>

            <h3>Did Paris Hilton Tweet About Chroo?</h3>
            <p>Yes, actually! She retweeted the book cover, which blew my mind. Funny how, when a huge celeb does something so trivial (to her), it's a big deal for others (like me). Naturally, I screen-grabbed it and wrote a little post about it (<a href="https://medium.com/hawthorne-crow/paris-hilton-tweeted-out-my-book-cover-which-was-pretty-cool-1da8a8f67170" target="_blank">here</a>).</p>

            <h3>CHROO Soundtrack:</h3>

            <p>Many people tell me that CHROO would make a great movie. That sounds about right to me, especially as the text makes reference to so many elements of pop culture, including music! As such, here's a playlist of various tunes referenced within the text. (Can you imagine the licensing fees if this ever got made into a movie?)</p>

            <iframe className="vidiframe" height="415" 
                src="https://www.youtube.com/embed/4-L6rEm0rnY?list=PLVrmQEVUJOfNm0ydwGrnjj7UI10sEkHU9" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen="">
            </iframe>

            <br></br>

            <Button href="https://www.amazon.com/gp/product/1705385133">Grab CHROO from Amazon!</Button>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default BookChroo;
