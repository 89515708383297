export const enhanceText = () => {
    const pre = document.getElementById('resumeText');
    if (pre) {
      const urlRegex = /https?:\/\/[^\s)]+/g;
      const uppercaseRegex = /\b[A-Z]+[0-9]*\b/g;
      const starLineRegex = /^★.*$/gm;  // Match lines starting with ★
  
      const exceptions = ['JSX', 'HTML', 'CSS', 'API', 'I', 'PHP', 'USD', 'NFT', 'SEO', 'AI', 'PR', 'JS', 'CMS', 'IPFS', 'CLI', 'REACH', 'ROI', 'SDK', 'BLAQ', 'ERC721', 'ETH', 'B', 'PA', 'A', 'OR'];  // Exception list
  
      const walker = document.createTreeWalker(pre, NodeFilter.SHOW_TEXT, null, false);
  
      while (walker.nextNode()) {
        const node = walker.currentNode;
        let text = node.nodeValue;

        text = text.replace(/B\.A\./g, 'B&#8228A&#8228');
        
        // Replace URLs first (reuse the previous linkify logic)
        text = text.replace(urlRegex, (url) => 
          `<a href="${url}" target="_blank">${url}</a>`
        );
  
        // Make UPPERCASE words bold, except for exceptions
        text = text.replace(uppercaseRegex, (word) => 
          exceptions.includes(word) ? word : `<b>${word}</b>`
        );
  
        // Make ★-lines bold
        text = text.replace(starLineRegex, (line) => 
          `<b>${line}</b>`
        );
  
        // Replace node while preserving existing formatting
        const tempContainer = document.createElement('span');
        tempContainer.innerHTML = text;
  
        node.replaceWith(...tempContainer.childNodes);
      }
    }
  };