import React, { useEffect } from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import { enhanceText } from './FunctionEnhanceText';

const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}

const text = `
JIM DEE  
Jim@GenerativeNFTs.io   •   (503) 891-7153
https://jpd3.com   •   https://linkedin.com/in/jimdee   •   https://github.com/jpdnft

____________________________________________________________________

PROFESSIONAL SUMMARY

A seasoned full-stack developer and technical writer with 15 years of experience producing high-quality API documentation, technical tutorials, and user guides. Proven expertise in taking projects from 0 to 1 by building comprehensive documentation and instructional materials from the ground up, ensuring seamless onboarding for developers and end-users. Adept at simplifying complex technical concepts and crafting content that empowers both technical and non-technical audiences. Highly skilled in collaborating with engineering teams to translate technical features into clear, accessible documentation.

____________________________________________________________________

TECHNICAL & EDITORIAL SKILLS

• Programming Languages, Tools, & Frameworks: 
  Solidity, React, JSX, JavaScript, TypeScript, PHP, MySQL, HTML, CSS, Hardhat, Node.js, Firebase, GitHub, blockchain & web3, misc APIs / SDKs

• Content Creation: 
  Technical tutorials, video walkthroughs, documentation, presentations, webinars

• Community Engagement: 
  Blogging, podcasts, developer meetups, open-source projects, public speaking

____________________________________________________________________

PROFESSIONAL EXPERIENCE

★ GenerativeNFTs.io ⟶ Co-Founder / Chief Generative NFT Coder / Project Lead
Location: Remote, Duration: Ongoing

• Co-founded GenerativeNFTs, overseeing the production of ~350,000 generative NFTs with sales exceeding $40 million USD.
• Created technical documentation and tutorials on Ethereum development, which were used by developer communities for onboarding.
• Managed complex international web3 projects -- pipeline management, coding, and team strategy advisory services.
• Deployed 30+ smart contracts on the Ethereum mainnet, contributing to the success of high-profile NFT projects.
• Maintained a popular (1+ million reads) web3/NFT blog, creating 200+ articles on NFT art, teams, strategy, and crypto trends.
• Provided advisory services for NFT drop teams, ensuring multi-million-dollar project sellouts.

★ Array Web Development ⟶ Principal Web Strategist
Location: Remote, Duration: 2011-2021

• Delivered high-quality web development projects using CMSs, PHP, MySQL, HTML, CSS, and JavaScript, consistently exceeding client expectations.
• Increased ROI for clients through effective digital marketing campaigns, SEO initiatives, and content creation strategies.
• Specialized in custom Joomla! development, including migrations, customizations, and advanced administration.
• Published hundreds of articles to enhance brand awareness and drive traffic, leveraging expertise in web development and digital marketing.

____________________________________________________________________

TECHNICAL WRITING SAMPLES

Sample articles in the tech-writing / SDK documentation area:

'Adding Realtime Crypto Coin Price Fetching to the Coinbase / Open AI Chatbot — Multi-Tool Customizations'
(https://medium.com/web-design-web-developer-magazine/adding-realtime-crypto-coin-price-fetching-to-the-coinbase-open-ai-chatbot-multi-tool-db0aba8dd997)

'Let's Code a Coinbase SDK AgentKit Tool that Empowers an Open AI Chatbot to Examine Smart Contracts!'
(https://medium.com/web-design-web-developer-magazine/lets-code-a-coinbase-sdk-agentkit-tool-that-empowers-an-open-ai-chatbot-to-examine-smart-contracts-c076b9b3dfee)

'Enhancing Coinbase AgentKit with Hex Data Transfers Using Zod and Ethers.js for a New Formal Tool'
(https://medium.com/web-design-web-developer-magazine/enhancing-coinbase-agentkit-with-hex-data-transfers-using-zod-and-ethers-js-for-a-new-formal-tool-564dba2c0fc9)

'Getting Started with Coinbase SDK Wallets: A Hands-On Guide'
(https://medium.com/web-design-web-developer-magazine/getting-started-with-coinbase-sdk-wallets-a-hands-on-guide-06ab896656b4)

'Expanding the Coinbase AgentKit: Using Chatbot Prompts to Create On-Chain NFTs and Beyond'
(https://medium.com/web-design-web-developer-magazine/expanding-the-coinbase-agentkit-using-chatbot-prompts-to-create-on-chain-nfts-and-beyond-4cc901bbacd1)

'Documenting an API using OpenAPI / Swagger — A Quick Example Using the Coinbase AI Chatbot Agent'
(https://medium.com/web-design-web-developer-magazine/documenting-an-api-using-openapi-swagger-a-quick-example-using-the-coinbase-ai-chatbot-agent-e2924af7e090)

'Building a Blockchain AI Agent in No Time with the Coinbase AgentKit'
(https://medium.com/web-design-web-developer-magazine/building-a-blockchain-ai-agent-in-no-time-with-coinbases-agentkit-a83ec7275bc3)

'Adding an AI-Generated 3D Spinning Cat to a React Ap Using Meshy.ai and ‘React Three Fiber’. Was It Easy?'
(https://medium.com/web-design-web-developer-magazine/adding-an-ai-generated-3d-spinning-cat-to-a-react-app-using-meshy-ai-4de44aa5d008?sk=ffabee08b3c3b137f97a567514248fe0)

'Implementing a “Hello World” WebSocket App on Heroku'
(https://medium.com/web-design-web-developer-magazine/implementing-a-hello-world-websocket-app-on-heroku-eab85abe08d0?sk=27f7b8ce02b3d62cdd5047f2c396b787)

'NFT Security Basics, Part 1 of 2: General Cyber Security'
(https://medium.com/web-design-web-developer-magazine/nft-security-basics-part-1-of-2-general-cyber-security-278e9c612313)

'NFT Security Basics, Part 2 of 2: Crypto Wallet Safety'
(https://medium.com/web-design-web-developer-magazine/nft-basics-the-top-800-things-anyone-can-easily-do-to-stay-safe-in-the-crypto-nft-space-717d743ed1a8)

'NFT Basics: How to Mint Your First NFT from an NFT Drop’s Mint Page'
(https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-mint-your-first-nft-from-an-nft-drops-mint-page-42b085053ff8)

'NFT Basics: How to Setup a MetaMask Wallet for Buying Ethereum NFTs'
(https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-setup-a-metamask-wallet-for-buying-ethereum-nfts-e85d4ba29523)

'What Is Bootstrap How Can I Use It In Joomla 3'
(https://medium.com/web-design-web-developer-magazine/what-is-bootstrap-how-can-i-use-it-in-joomla-3-669a6a40614a?sk=ab05fbb5376369ad4b64f64e761c4220)

'How To Administer a Joomla Web Site — Part 1: Categorization of Your Content'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-1-categorization-of-your-content-bc9eca0999a?sk=65210bccd138b1e25ce656b6350a96f8)

'How To Administer a Joomla Web Site — Part 2: Creating Articles and Categorizing Them'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-2-creating-articles-and-categorizing-them-a39c081f5f60?sk=d66b2ef4f182566dc3faab01795c4e22)

'How To Administer a Joomla Web Site — Part 3: Menu Administration'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-3-menu-administration-af8427bdd1d5?sk=28b355fe19718e8ace11977fbcadad8a)

'How To Administer a Joomla Web Site — Part 4: Filters and Search Tools'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-4-filters-and-search-tools-92f77d97f8c?sk=21516cb5d6ee0db09163d51ef6bb1416)

'Can I Run My Own PHP Scripts Within Joomla or WordPress Pages'
(https://medium.com/web-design-web-developer-magazine/can-i-run-my-own-php-scripts-within-joomla-or-wordpress-pages-3521ad2e9d6c?sk=5f33cdf1cbb05914264e6b2ea3478a0d)

'What Does Joomla 4 Mean for Your Joomla 3 based Web Site'
(https://medium.com/web-design-web-developer-magazine/what-does-joomla-4-mean-for-your-joomla-3-based-web-site-5f3e814b987?sk=e04d10d9f27c423fa2844c6db6acb6a9)

'How To Integrate Web Sites with YouTube's API: A Simple Example Using PHP'
(https://medium.com/web-design-web-developer-magazine/how-to-integrate-web-sites-with-youtubes-api-a-simple-example-using-php-55f308afe6c5?sk=fc0b26d9c7dc552f97ecc5d77b755f4e)

'Leveraging JS File Uploader Libraries in PHP Forms Example using FilePond'
(https://medium.com/web-design-web-developer-magazine/leveraging-js-file-uploader-libraries-in-php-forms-example-using-filepond-754cdc9a8b48?sk=8acdac4ef204d2dc31ffc7bb501b851a)

'Using Arweave Through ArDrive for Storing 10,000 Generative NFTs Permanently How to Get It Done'
(https://medium.com/web-design-web-developer-magazine/using-arweave-through-ardrive-for-storing-10-000-generative-nfts-permanently-how-to-get-it-done-a80ac8e36f30?sk=a8d3e092f43d66fcf62b2f8ca5c2f5c6)

'NFT Teams: Free Yourself from Never-Ending IPFS Pinning Fees: Uploading Images to Arweave via the CLI Method'
(https://medium.com/web-design-web-developer-magazine/nft-teams-free-yourself-from-never-ending-ipfs-pinning-fees-uploading-images-to-arweave-via-the-a94ca51e99f4?sk=1ee94409f42858a96d9c0be638328efd)

'Marketing Tweets using REACH Experimenting With a Platform That Helps Creators Reach Audiences'
(https://medium.com/web-design-web-developer-magazine/marketing-tweets-using-reach-experimenting-with-a-platform-that-helps-creators-reach-audiences-5ef2d76825a3?sk=912cc85db18ccadd645d6eef41906373)

'How To Integrate Web Sites with YouTube's API A Simple Example Using PHP'
(https://medium.com/web-design-web-developer-magazine/how-to-integrate-web-sites-with-youtubes-api-a-simple-example-using-php-55f308afe6c5?sk=fc0b26d9c7dc552f97ecc5d77b755f4e)

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/

`;


const ResumeTechWriter = () => {

  useEffect(() => {
    enhanceText();
  }, []);

  return (
    <div>
         
         <MainHeader />

         <style>{`
          @media print {
            div.mainpageHeader, p.breadcrumbs, h1, div.mainpageFooter, p.xprint, textarea { display: none; }
            div.mainpage { border:initial; }
          }
        `}</style>

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>SENIOR TECHNICAL WRITER / API DOCUMENTATION SPECIALIST</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p class="xprint"><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p class="xprint" className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeTechWriter;
