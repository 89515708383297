import React, { useEffect } from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import { enhanceText } from './FunctionEnhanceText';


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
Jim@GenerativeNFTs.io   •   (503) 891-7153
https://jpd3.com   •   https://linkedin.com/in/jimdee   •   https://medium.com/@jimdee

____________________________________________________________________

PROFESSIONAL SUMMARY

Prolific writer, editor, and content manager with extensive experience in producing high-quality publications, managing large-scale projects, and creating engaging content across various platforms. Adept at combining creative and technical skills to enhance communication strategies and drive audience engagement. Well-accustomed to remote work / consultative scenarios.

____________________________________________________________________

PROFESSIONAL EXPERIENCE

★ Co-Founder / Chief Generative NFT Coder / Project Lead ⟶ GenerativeNFTs.io

• Co-founded GenerativeNFTs.io and established a popular web3/NFT blog.
• Wrote and edited hundreds of articles on generative NFT art, team building, and crypto trends.
• See: https://medium.com/web-design-web-developer-magazine
• Managed content strategy and publication for an international audience, contributing to significant sales growth and industry recognition.
• Produced comprehensive technical documentation and marketing materials.


★ Principal Web Strategist ⟶ Array Web Development

• Executed state-of-the-art websites, redesigns, and digital marketing campaigns.
• Published hundreds of articles to demonstrate expertise, enhance branding, and increase traffic.
• See: https://medium.com/web-design-web-developer-magazine
• Specialized in content creation, SEO, and social media marketing, achieving significant ROI for clients.


★ Director of Marketing & Business Development ⟶ Case | Sabatini CPAs

• Built and executed comprehensive marketing and business development plans.
• Created high-quality marketing materials, proposals, and business communications.
• Launched a successful tax eNewsletter, significantly expanding the firm's client base.


★ Senior Manager, Marketing Communications & Internet Services ⟶ Air & Waste Management Association

• Oversaw all marketing communications and web-based publishing.
• Supervised marketing, editorial, and website personnel.
• Managed PR, advertising, and media relations for large-scale conferences and exhibitions.


★ Marketing Communications Project Manager ⟶ Deloitte

• Wrote and managed more than 200 proposals for audit, tax, and consulting engagements.
• Developed competitive intelligence programs and assisted with business development strategies.


★ Production Editor ⟶ Prentice Hall

• Developed sound editorial judgment and knowledge of journalistic standards and processes.
• Edited and managed layout for legal journals, ensuring compliance with leading style guides.
• Wrote feature articles for the law firm management publication, Of Counsel.

____________________________________________________________________

RELEVANT EDITORIAL SKILLS

blogging, book editing, communications, content creation & strategy, content management systems, copy editing, copywriting, corporate communications, creative writing, editing, editorial management, feature writing, journalism, kindle publishing, magazines and publication management, marketing communications, media relations, medium.com expertise, newspaper experience, nonfiction writing, newsletter production and management, novel writing, project management, public relations (PR), press releases, publications management, publishing, SEO content, strategic communications, technical writing & documentation, web publishing, HTML, writing

____________________________________________________________________

GENERAL BUSINESS SKILLS

agency experience, board of directors experience, budgeting, competitive intelligence, consulting, content strategy, creativity, critical thinking, crm, entrepreneurism, event planning, executive interfacing, hr, interpersonal skills, leadership, management, marketing communications, marketing strategy, media relations, microsoft excel, microsoft office, microsoft word, multimedia content management, negotiation, nonprofit organizations, organization skills, partnership management, pr / public relations, presenting, problem solving, project management, proposals, public speaking, research, resourcefulness, rfps, strategic planning, strategy, supervision, team building, team leadership, teamwork, video production 

____________________________________________________________________

INDUSTRY EXPERIENCE

blockchain, construction, crypto / cryptocurrency, editorial, financial services, legal, magazines, news, nonprofit organizations, professional services, publishing, saas, startups, technology

____________________________________________________________________

PUBLICATIONS & WRITING

• Authored two novels, a novella, a feature screenplay, and a memoir.
• Published thousands of blog articles on various platforms.
• Notable articles include topics on web development, generative art coding, and digital marketing strategies.
• See https://jpd3.com/books


•  REPRESENTATIVE RECENT ARTICLES:

'Adding Realtime Crypto Coin Price Fetching to the Coinbase / Open AI Chatbot — Multi-Tool Customizations'
(https://medium.com/web-design-web-developer-magazine/adding-realtime-crypto-coin-price-fetching-to-the-coinbase-open-ai-chatbot-multi-tool-db0aba8dd997)

'Let's Code a Coinbase SDK AgentKit Tool that Empowers an Open AI Chatbot to Examine Smart Contracts!'
(https://medium.com/web-design-web-developer-magazine/lets-code-a-coinbase-sdk-agentkit-tool-that-empowers-an-open-ai-chatbot-to-examine-smart-contracts-c076b9b3dfee)

'Enhancing Coinbase AgentKit with Hex Data Transfers Using Zod and Ethers.js for a New Formal Tool'
(https://medium.com/web-design-web-developer-magazine/enhancing-coinbase-agentkit-with-hex-data-transfers-using-zod-and-ethers-js-for-a-new-formal-tool-564dba2c0fc9)

'Getting Started with Coinbase SDK Wallets: A Hands-On Guide'
(https://medium.com/web-design-web-developer-magazine/getting-started-with-coinbase-sdk-wallets-a-hands-on-guide-06ab896656b4)

'Expanding the Coinbase AgentKit: Using Chatbot Prompts to Create On-Chain NFTs and Beyond'
(https://medium.com/web-design-web-developer-magazine/expanding-the-coinbase-agentkit-using-chatbot-prompts-to-create-on-chain-nfts-and-beyond-4cc901bbacd1)

'Documenting an API using OpenAPI / Swagger — A Quick Example Using the Coinbase AI Chatbot Agent'
(https://medium.com/web-design-web-developer-magazine/documenting-an-api-using-openapi-swagger-a-quick-example-using-the-coinbase-ai-chatbot-agent-e2924af7e090)

'Building a Blockchain AI Agent in No Time with the Coinbase AgentKit'
(https://medium.com/web-design-web-developer-magazine/building-a-blockchain-ai-agent-in-no-time-with-coinbases-agentkit-a83ec7275bc3)


•  REPRESENTATIVE ARTICLES ABOUT WRITING:

'The Comma Sutra: Punctuation and Beyond for Controlling How Readers Hear Your Words'
(https://writingcooperative.com/the-comma-sutra-punctuation-and-beyond-for-controlling-how-readers-hear-your-words-8972e4a7af15?sk=4bae6e762d2d991c3b818fdb700b1e12)

'How to Become an Awesome Writer. See Two Simple Ways Now'
(https://writingcooperative.com/how-to-become-an-awesome-writer-see-two-simple-ways-now-f98f02828268?sk=40c2bd3ce64e064eade999654a68414d)

'Are You "Very Happy" or "Effervescent"? How Avoiding "Very" Can Improve Your Writing'
(https://writingcooperative.com/are-you-very-happy-or-effervescent-how-avoiding-very-can-improve-your-writing-84c699d72450?sk=04ec68f879c7cf91cf125c406c98ad51)

'Research and Business Development Two Key Components for Proposal Success'
(https://medium.com/web-design-web-developer-magazine/research-and-business-development-two-key-components-for-proposal-success-2354042d0375?sk=f19eddf4bdc72bc68226eee6aa234536)

'Lessons Learned While Writing 700 B2B Proposals Best Practices and Observations for Winning Work'
(https://medium.com/web-design-web-developer-magazine/lessons-learned-while-writing-700-b2b-proposals-best-practices-and-observations-for-winning-work-80379d34c29e?sk=0db54fa4eeee35c129ec4086fe29c2c3)

'Some Notes on Writing Interview Articles'
(https://writingcooperative.com/some-notes-on-writing-interview-articles-7e0776214f90?sk=d98745907d72d14c946d6848f9a28ab1)

'Does Writing Keep Getting Harder For You to Do? Maybe That's Good News'
(https://writingcooperative.com/does-writing-keep-getting-harder-for-you-to-do-maybe-thats-good-news-be94cfee329d?sk=1cfd32ec6b6c7b349b80df43df523ca7)

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

HOBBIES AND INTERESTS

• Stained glass work: https://jpd3.com/glass
• Music composition: https://jpd3.com/music
• Digital art: https://jpd3.com/art
• NFTs and web3: https://jpd3.com/nfts

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/


`;



const ResumeEditorial = () => {

    useEffect(() => {
      enhanceText();
    }, []);
  
  return (
    <div>
         
         <MainHeader />

         <style>{`
          @media print {
            div.mainpageHeader, p.breadcrumbs, h1, div.mainpageFooter, p.xprint, textarea { display: none; }
            div.mainpage { border:initial; }
          }
        `}</style>

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>EDITORIAL RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p class="xprint"><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p class="xprint" className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeEditorial;
